import { createColumnHelper } from "@tanstack/react-table"
import {  Heading, IconButton, Menu, MenuButton, MenuItem, MenuList, useDisclosure, useToast } from "@chakra-ui/react"
import { useSkeletonLoader } from "@hooks/useSkeletonLoader"
import { MdMoreVert, MdDelete, MdEdit, MdAdd } from "react-icons/md"
import { useCallback, useState } from "react"
import { useTeam  } from '@hooks/useTeam'
import DataTable from "@components/table/DataTable"
import EditMemberModal from "../team/EditMemberModal"
import AddMemberModal from "../team/AddMemberModal"
import PropTypes from 'prop-types'
import { useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router'

const menuOptions =  [
    { label: 'Delete', icon: <MdDelete/>, action: 'delete'},
    { label: 'Edit', icon: <MdEdit/>, action: 'edit'}
]

const groupActions =  [
    { label: 'Add', icon: <MdAdd/>, action: 'add'},
]

const hiddenColumns = { id: false, email: false } 


const TeamListing = ({teamQuery, calendarQuery, refresh, pagination,setPagination, chatbotId}) => {
    const { onClose: onEditMemberClose, onOpen: onEditMemberOpen, isOpen: isEditMemberOpen } = useDisclosure()
    const { onClose: onAddMemberClose, onOpen: onAddMemberOpen, isOpen: isAddMemberOpen } = useDisclosure()
    const [teamMember, setTeamMember] = useState({})
    const toast = useToast()
    const team = useTeam(toast)
    const queryClient = useQueryClient()

    const onPageChange = useCallback((pageState) => {
        setPagination(pageState)
    }, [setPagination])

    const handleAction = async (event, row, action) => {
        event.preventDefault()
        if (action === 'edit') {
            setTeamMember(row.original)
            onEditMemberOpen()
        } else if(action === 'delete') {
            try {
                await team.deleteTeamMember({id: row.original.id})
                queryClient.invalidateQueries({ queryKey: ['org_team',  row.original.org_id] })
                refresh()
            }catch(error) {
                console.log(error)
            }
        }
    }

    const handleBulkAction = async (action, records) => {
        if ( action === 'add') {
            console.log(action)
            onAddMemberOpen()
        } else {
            records.forEach((row) => {
                console.log(row)
            })
            console.log(action)
        } 
    }

    const addMember = async (data) => {
        try {
            data.chatbot_id = chatbotId
            await team.addTeamMember(data) 
            queryClient.invalidateQueries({ queryKey: ['chatbot_team', chatbotId] })
            refresh()
        }catch(error ) {
            console.log(error)
        }  
    }

    const updateMember = async (data, memberId) => {
        try {
            data.id = memberId
            data.chatbot_id = chatbotId
            await team.updateTeamMember(data)
            queryClient.invalidateQueries({ queryKey: ['chatbot_team', chatbotId] })
            refresh()
        }catch(error ) {
            console.log(error)
        }
    }
       
    const renderActions = (row, actions) => {
        const actionButtons = []
        actions.forEach((action, index) => {
            const isDisabled = !!(action.action === 'add')
            actionButtons.push(<MenuItem isDisabled={isDisabled} key={index} icon={action.icon} onClick={(event) => handleAction(event, row, action.action)}>{action.label}</MenuItem>)
        })
        return actionButtons
    }  

    const columnHelper = createColumnHelper()
    const columns = [
        columnHelper.accessor("id", {
            header: 'ID',
            enableHiding: true,
            cell: (info) => info.getValue(),
        }),
        columnHelper.accessor("name", {
            header: 'Name',
            cell: (info) => info.getValue(),
            enableColumnFilter: true
        }),
        columnHelper.accessor("phone_number", {
            header: 'Mobile',
            cell: (info) => info.getValue(),
            enableColumnFilter: true
        }),
        columnHelper.accessor("email", {
            header: 'Email',
            cell: (info) => info.getValue(),
            enableHiding: true,
            enableColumnFilter: false
            
        }),
        columnHelper.accessor("department", {
            header: 'Department',
            cell: (info) => info.getValue(),
            enableColumnFilter: true
        }),
        columnHelper.display({
            header: 'Actions',
            cell: props => 
            <Menu>
            <MenuButton
            as={IconButton}
            aria-label='Options'
            icon={<MdMoreVert />}
            variant='outline'
            />
            <MenuList>
            {renderActions(props.row, menuOptions)}
            </MenuList>  
            </Menu>
        })
    ]
    const { tableData, tableColumns } = useSkeletonLoader(columnHelper, columns, teamQuery)
    return (
       <>
        <Heading p={4} as="h1" size="lg" mb="6">Team</Heading>
        <DataTable tableQuery={tableData} pagination={pagination} columns={tableColumns} multiSelect={false} onPageChange={onPageChange} bulkActions={groupActions} handleGroupAction={handleBulkAction} hiddenColumns={hiddenColumns} />
        {teamQuery.isFetched && calendarQuery.isFetched && (
            <>
            <EditMemberModal isOpen={isEditMemberOpen} onClose={onEditMemberClose} member={teamMember} calendar={calendarQuery.data.rows}  onUpdate={updateMember} />
            <AddMemberModal isOpen={isAddMemberOpen} onClose={onAddMemberClose} calendar={calendarQuery.data.rows} onAdd={addMember}/>
            </>
        )}
       </>
    )
}

TeamListing.propTypes = {
    teamQuery: PropTypes.object,
    calendarQuery: PropTypes.object,
    refresh: PropTypes.func,
    pagination: PropTypes.object,
    setPagination: PropTypes.func,
    chatbotId: PropTypes.string
}
export default TeamListing