import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    VStack,
    FormControl,
    FormLabel,
    Switch,
    Text,
  } from '@chakra-ui/react';
  import { useState, useEffect } from 'react';
  import PropType from 'prop-types'

  
  export const SettingsModal = ({
    isOpen,
    onClose,
    onSubmit,
    shortCode,
    initialSettings,
    isLoading = false,
  }) => {
    const [settings, setSettings] = useState({quickBot: false, dataSync: false, addUsersToTeam: false});
    const [syncWarning, setSyncWarning] = useState('');
    useEffect(() => {
        setSettings(initialSettings);
    }, [initialSettings])
  
    const handleSubmit = () => {
      onSubmit(shortCode, settings);
    };
  
    const updateDataSync = (value) => {
        if (!value) {
            setSyncWarning('⚠️ Disabling data sync will effect excel sheet data analysis');
        } else {
            setSyncWarning('');
        }
        setSettings({ ...settings, dataSync: value })
    }
    const switchStyles = {
      '--switch-track-width': '36px',
      '--switch-track-height': '16px',
      '--switch-track-bg': '#E2E8F0',
      '--switch-track-checked-bg': '#25D366',
      '--switch-thumb-x': '16px',
      '--switch-thumb-bg': '#25D366',
      '--switch-thumb-checked-bg': '#FFFFFF',
      '.chakra-switch__track': {
        width: 'var(--switch-track-width)',
        height: 'var(--switch-track-height)',
        bg: 'var(--switch-track-bg)',
        _checked: {
          bg: 'var(--switch-track-checked-bg)',
        },
      },
      '.chakra-switch__thumb': {
        width: '16px',
        height: '16px',
        bg: 'var(--switch-thumb-bg)',
        _checked: {
          transform: 'translateX(var(--switch-thumb-x))',
          bg: 'var(--switch-thumb-checked-bg)',
        },
      },
    };
  
    return (
      <Modal isOpen={isOpen} onClose={onClose} size="md">
        <ModalOverlay backdropFilter="blur(4px)" />
        <ModalContent >
          <ModalHeader >Chatbot Settings</ModalHeader>
          <ModalCloseButton  />
          
          <ModalBody>
            <VStack spacing={6}>
              <FormControl display="flex" alignItems="center" justifyContent="space-between">
                <FormLabel htmlFor="quick-bot" mb={0}>
                  <Text fontWeight="medium" >Quick Bot Format</Text>
                  <Text fontSize="sm" color="gray.600">Enable quick response format for this bot</Text>
                </FormLabel>
                <Switch
                  id="quick-bot"
                  isChecked={settings.quickBot}
                  onChange={(e) => setSettings({ ...settings, quickBot: e.target.checked })}
                  sx={switchStyles}
                  size='lg'
                />
              </FormControl>
  
              <FormControl display="flex" alignItems="center" justifyContent="space-between">
                <FormLabel htmlFor="data-sync" mb={0}>
                  <Text fontWeight="medium" >Data Sync</Text>
                  <Text fontSize="sm" color="gray.600">{syncWarning === '' ? "Enable excel data sync" : `${syncWarning}`}</Text>
                </FormLabel>
                <Switch
                  id="data-sync"
                  size='lg'
                  isChecked={settings.dataSync}
                  onChange={(e) => updateDataSync(e.target.checked)}
                  sx={switchStyles}
                />
              </FormControl>
  
              <FormControl display="flex" alignItems="center" justifyContent="space-between">
                <FormLabel htmlFor="add-users" mb={0}>
                  <Text fontWeight="medium" >Add Users to Team</Text>
                  <Text fontSize="sm" color="gray.600">Automatically add users who join bot to team</Text>
                </FormLabel>
                <Switch
                  id="add-users"
                  isChecked={settings.addUsersToTeam}
                  onChange={(e) => setSettings({ ...settings, addUsersToTeam: e.target.checked })}
                  sx={switchStyles}
                  size='lg'
                />
              </FormControl>
            </VStack>
          </ModalBody>
  
          <ModalFooter gap={3}>
            <Button
              variant="ghost"
              onClick={onClose}
              isDisabled={isLoading}
            >
              Cancel
            </Button>
            <Button
              colorScheme="whatsapp"
              onClick={handleSubmit}
              isLoading={isLoading}
              loadingText="Saving"
            >
              Save Changes
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    );
  };

SettingsModal.propTypes = {
    isOpen: PropType.bool.isRequired,
    onClose: PropType.func.isRequired,
    onSubmit: PropType.func.isRequired,
    shortCode: PropType.string.isRequired,
    initialSettings: PropType.object.isRequired,
    isLoading: PropType.bool,
};

export default SettingsModal;